<template>
  <div id="support-form">
    <v-card elevation="2" class="d-flex justify-center mb-6">
      <h2> Support </h2>
      <form action="https://formspree.io/f/xvodnlpy" method="POST">
        <label>
          Your email: 
          <br/>
          <input type="email" name="_replyto" placeholder="abc@abc.com" />
        </label>
        <br/>
        <br/>
        <label>
          Your message: 
          <br/>
        </label>
          <textarea name="message" rows="5" placeholder="Message"> </textarea>
        <br />
        <br/>
        <!-- your other form fields go here -->
        <v-btn type="submit">Send</v-btn>
      </form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AppSupport",
  components: {
    
  }
};
</script>

<style scoped>
#support-form {
  width: 25%;
  text-align: left;
  min-width: 300px;
  margin: auto;
}
h2{
  text-align: center;
  padding: 16px;
}
textarea {
  border: 1px solid #888;
  padding: 16px;
}
.v-card {
  padding: 24px;
}
input {
  padding: 16px;
  border: 1px solid #888
}

</style>