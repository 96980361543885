<template>
  <div class="home">
    <img alt="McKay Photo" src="../assets/mckay_websize.jpg" style="width:50%;height:100%;">
    <h1>
      Welcome to McKay's Website
    </h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    
  }
}
</script>

<style scoped>
.home {
  margin: auto
}
h1 {
  padding: 24px;
}
</style>