import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AppSupport from '../views/AppSupport'
import PrivacyPolicy from '../views/PrivacyPolicy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/support',
    name: 'Support',
    component: AppSupport
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
